<template>
  <Link :to="to" class="card-one">
    <div class="card-one__thumbnail">
      <ImageResponsive
        v-if="image"
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
        class="card-one__thumbnail__image"
      />
      <div
        class="card-one__thumbnail__duration"
        :class="{ backgroundAds: isAdvertorial }"
        v-if="!live"
      >
        <p class="advertorial" v-if="isAdvertorial">Ad</p>
        <div class="article-reguler" v-else-if="isText">
          <!-- <Icons name="book" /> -->
          <i class="bi bi-book-half"></i>
          <p class="text">TEKS</p>
        </div>
        <p class="duration" v-else>{{ duration }}</p>
      </div>
      <div class="card-one__thumbnail__live" v-else>
        <Icons name="live" color="#fafafa" class="live-icon" />
        <p class="live-text">LIVE</p>
      </div>
    </div>
    <div class="card-one__body">
      <div class="card-one__body__channel">{{ channel }}</div>
      <div class="card-one__body__title">
        {{ title }}
      </div>
      <div class="card-one__body__date">
        {{ $moment(date).utc().format("DD MMMM YYYY") }}
      </div>
    </div>
  </Link>
</template>

<script>
import ImageResponsive from "@/components/ImageResponsive";
import Icons from "@/components/Icons";
import Link from "@/components/Link";

export default {
  components: {
    ImageResponsive,
    Icons,
    Link,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: [Number, String],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    to: {
      type: [Object, String],
      default: "",
    },
    live: {
      type: Number,
      default: 0,
    },
    isText: {
      type: Boolean,
      default: false,
    },
    isAdvertorial: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-one {
  width: 250px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    width: 167.73px;
  }
  &__thumbnail {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
      border-radius: 6px;
      // height: 93.92px;
      // width: 167.73px;
    }
    &__image {
      width: 100%;
      overflow: hidden;
      height: auto;
    }
    &__duration {
      background: rgba(0, 0, 0, 0.7);
      padding: 3px 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      @media only screen and (max-width: 1024px) {
        border-top-left-radius: 6px;
      }
      .article-reguler {
        display: flex;
        align-items: center;
        .bi-book-half {
          width: 16px;
          height: auto;
          margin-right: 2px;
          color: #ffffff;
        }
        .text {
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
          margin: 0;
        }
      }
      &.backgroundAds {
        background: rgba(128, 128, 128, 0.7);
        padding: 3px 8px;
      }
      .advertorial {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .duration {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
    }
    &__live {
      position: absolute;
      bottom: 0;
      right: 0;
      background: #e73638;
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 3px 6px;
      border-top-left-radius: 10px;
      @media only screen and (max-width: 1024px) {
        border-top-left-radius: 6px;
      }
      .live-icon {
        width: 20px;
        height: 20px;
        margin-right: 5px;
        -webkit-animation: breathing 1s ease-out infinite normal;
        animation: breathing 1s ease-out infinite normal;
        @media only screen and (max-width: 1024px) {
          width: 15px;
          height: 15px;
        }
        @keyframes breathing {
          0% {
            -webkit-transform: scale(0.8);
            -ms-transform: scale(0.8);
            transform: scale(0.8);
          }

          25% {
            -webkit-transform: scale(1);
            -ms-transform: scale(1);
            transform: scale(1);
          }

          60% {
            -webkit-transform: scale(0.8);
            -ms-transform: scale(0.8);
            transform: scale(0.8);
          }

          100% {
            -webkit-transform: scale(0.8);
            -ms-transform: scale(0.8);
            transform: scale(0.8);
          }
        }
      }
      .live-text {
        margin: 0;
        color: #fafafa;
        font-size: 12px;
        font-weight: 700;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
    }
  }
  &__body {
    padding: 16px 0;
    @media only screen and (max-width: 1024px) {
      padding: 12px 0;
    }
    &__channel {
      font-size: 12px;
      font-weight: 500;
      color: #ffe900;
      margin-bottom: 8px;
      @media only screen and (max-width: 1024px) {
        font-size: 11px;
        margin-bottom: 4px;
      }
    }
    &__title {
      font-size: 16px;
      font-weight: 500;
      color: #fafafa;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
    &__date {
      font-size: 12px;
      font-weight: 400;
      color: #bdbdbd;
      margin: 0;
      @media only screen and (max-width: 1024px) {
        font-size: 11px;
      }
    }
  }
}
</style>
