<template>
  <div class="card-six swiper-slide">
    <Link :to="toDetail" class="card-six__thumbnail">
      <ImageResponsive
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
        class="card-six__thumbnail__image"
      />
      <div class="card-six__thumbnail__total-video">
        <Icons name="circle-play" color="#ffffff" />
        <p class="total-video">{{ totalVideo }} Video</p>
      </div>
    </Link>
    <div class="card-six__body">
      <h2 class="card-six__body__title">{{ title }}</h2>
      <Link :to="to" class="card-six__body__playlist">
        LIHAT PLAYLIST SELENGKAPNYA
      </Link>
    </div>
  </div>
</template>

<script>
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    Icons,
    ImageResponsive
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    totalVideo: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    to: {
      type: [Object, String],
      default: "",
    },
    toDetail: {
      type: [Object, String],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.card-six {
  width: 250px;
  height: fit-content;
  display: flex;
  width: 800px;
  flex-direction: row;
  padding: 16px 0;
  border-bottom: 1px solid lightgray;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
  &:nth-child(6) {
    padding-top: 0;
  }
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
  &__thumbnail {
    width: 35%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
      border-radius: 6px;
      width: 40%;
    }
    &__image {
      width: 100%;
      height: auto;
      @media only screen and (max-width: 1024px) {
        height: auto;
      }
    }
    &__total-video {
      width: 100%;
      height: auto;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 0;
      svg {
        width: 14px;
        height: 14px;
        margin-right: 3px;
        @media only screen and (max-width: 1024px) {
          width: 10px;
          height: 10px;
          margin-right: 3px;
        }
      }
      .total-video {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
    }
  }
  &__body {
    width: 65%;
    padding: 0;
    padding-left: 20px;
    align-self: center;
    @media only screen and (max-width: 1024px) {
      width: 60%;
      padding-left: 16px;
    }
    &__title {
      font-size: 20px;
      font-weight: 500;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      color: black;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
    &__playlist {
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      color: #4a25aa;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
        color: #616161;
      }
      &:hover {
        color: #4a25aa !important;
      }
    }
  }
}
</style>
