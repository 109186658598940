var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Link',{staticClass:"card-four",attrs:{"to":_vm.to}},[_c('div',{staticClass:"card-four__logo-channel"},[(_vm.imageLogo)?_c('ImageResponsiveSmall',{attrs:{"imageUrl":_vm.imageLogo,"fromUrl":_vm.imageLogoFromUrl}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"card-four__thumbnail"},[(_vm.image)?_c('ImageResponsive',{staticClass:"card-four__thumbnail__image",attrs:{"imageUrl":_vm.image,"imageUrlMobile":_vm.image,"fromUrl":_vm.imageFromUrl}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-four__thumbnail__duration",class:{ backgroundAds: _vm.isAdvertorial }},[(_vm.isAdvertorial)?_c('p',{staticClass:"advertorial"},[_vm._v("Ad")]):(_vm.isText)?_c('div',{staticClass:"article-reguler"},[_c('i',{staticClass:"bi bi-book-half"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("TEKS")])]):_c('p',{staticClass:"duration"},[_vm._v(_vm._s(_vm.duration))])])],1),_vm._v(" "),_c('div',{staticClass:"card-four__body"},[_c('div',{staticClass:"card-four__body__channel-category"},[_c('Link',{staticClass:"channel",attrs:{"to":{
          name: 'program-childBrand-slugProgram',
          params: {
            childBrand: _vm.programSlug,
            slugProgram: _vm.channelSlug,
          },
          query: {
            type: 'highlights',
          },
        }}},[_vm._v(_vm._s(_vm.channel))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.categorySlug),expression:"categorySlug"}]},[_vm._v("•")]),_vm._v(" "),_c('Link',{directives:[{name:"show",rawName:"v-show",value:(_vm.categorySlug),expression:"categorySlug"}],staticClass:"category",attrs:{"to":{
          name: 'interest',
          query: {
            type: _vm.categorySlug,
          },
        }}},[_vm._v(_vm._s(_vm.category))])],1),_vm._v(" "),_c('div',{staticClass:"card-four__body__title"},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }