<template>
  <Link :to="to">
    <div v-if="!bgWhite" class="card-twelve">
      <div class="img-wrapper" v-if="image">
        <ImageResponsive
          v-if="image"
          :imageUrl="image"
          :fromUrl="imageFromUrl"
          class="card-twelve-img-top"
          alt=""
        />
      </div>
      <div class="card-twelve-body d-flex flex-column justify-content-between">
        <div class="category-wrapper d-flex align-items-center">
          <!-- <div class="card-twelve-organizer">{{ organizer }}</div>
          <div class="dots">&bull;</div> -->
          <div
            class="card-twelve-category"
            v-for="category in categories.length > 0
              ? categories.length > 2
                ? categories.slice(0, 2)
                : categories
              : [
                  {
                    id: '1',
                    title: 'Uncategorized',
                  },
                ]"
            :key="category.id"
          >
            {{ category.title }} <span>&bull;</span>
          </div>
        </div>
        <div class="text-white title-wrapper">
          <div class="card-twelve-title fs-16">
            {{ title }}
          </div>
          <div class="card-twelve-desc fs-12">
            {{ description }}
          </div>
        </div>
        <hr v-if="!bgWhite" />
        <div class="schedule-wrapper d-flex justify-content-between">
          <div class="box card-twelve-datetime d-flex align-items-center">
            <Icons :name="bgWhite ? 'calendar' : 'calendar-white'" />
            <div class="date">{{ $moment(datetime).format("DD/MM/YYYY") }}</div>
          </div>
          <div class="box card-twelve-location d-flex align-items-center">
            <Icons :name="bgWhite ? 'location' : 'location-white'" />
            <div class="location">
              {{ location }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="bgWhite" class="card-twelve-white">
      <div class="img-wrapper is-desktop">
        <ImageResponsive
          v-if="image"
          :imageUrl="image"
          :fromUrl="imageFromUrl"
          class="card-twelve-img-top"
          alt=""
        />
      </div>
      <div class="card-twelve-body d-flex flex-column justify-content-between">
        <div class="category-wrapper d-flex align-items-center">
          <!-- <div class="card-twelve-organizer">{{ organizer }}</div>
          <div class="dots">&bull;</div> -->
          <div
            class="card-twelve-category"
            v-for="category in categories.length > 0
              ? categories.length > 2
                ? categories.slice(0, 2)
                : categories
              : [
                  {
                    id: '1',
                    title: 'Uncategorized',
                  },
                ]"
            :key="category.id"
          >
            {{ category.title }} <span>&bull;</span>
          </div>
        </div>
        <div class="text-white title-wrapper">
          <div class="card-twelve-title fs-16">
            {{ title }}
          </div>
          <div class="card-twelve-desc fs-12">
            {{ description }}
          </div>
        </div>
        <hr v-if="!bgWhite" />
        <div class="schedule-wrapper d-flex justify-content-between">
          <div class="box card-twelve-datetime d-flex align-items-center">
            <Icons :name="bgWhite ? 'calendar' : 'calendar-white'" />
            <div class="date">{{ $moment(datetime).format("DD/MM/YYYY") }}</div>
          </div>
          <div class="box card-twelve-location d-flex align-items-center">
            <Icons :name="bgWhite ? 'location' : 'location-white'" />
            <div class="location">
              {{ location }}
            </div>
          </div>
        </div>
      </div>
      <div class="content-wrapper-bottom is-mobile">
        <div class="img-wrapper">
          <ImageResponsive
            v-if="image"
            :imageUrl="image"
            :imageUrlMobile="image"
            :fromUrl="imageFromUrl"
            class="card-twelve-img-top"
            alt=""
          />
        </div>
        <div class="schedule-wrapper d-flex justify-content-between">
          <div class="box card-twelve-datetime d-flex align-items-center">
            <Icons :name="bgWhite ? 'calendar' : 'calendar-white'" />
            <div class="date">{{ $moment(datetime).format("DD/MM/YYYY") }}</div>
          </div>
          <div class="box card-twelve-location d-flex align-items-center">
            <Icons :name="bgWhite ? 'location' : 'location-white'" />
            <div class="location">
              {{ location }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Link>
</template>

<script>
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    Icons,
    ImageResponsive
  },
  props: {
    bgWhite: {
      type: Boolean,
      default: false,
    },
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    organizer: {
      type: String,
      default: "",
    },
    categories: {
      type: [Object, String, Array],
      default: "",
    },
    location: {
      type: [Object, String],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    datetime: {
      type: String,
      default: "",
    },
    to: {
      type: [Object, String],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
// card-twelve BACKGROUND TRANSPARENT
a {
  display: block;
  .card-twelve {
    width: 100%;
    min-width: 250px;
    border-radius: 6px;
    overflow: hidden;
    height: fit-content;
    background: transparent;
    border: none;
    @media only screen and (max-width: 1024px) {
      height: fit-content;
    }
    .img-wrapper {
      width: 100%;
      height: auto;
    }
    .card-twelve-body {
      padding: 16px;
      font-size: 12px;
      color: white;
      min-height: 240px;
      @media only screen and (max-width: 1024px) {
        min-height: 220px;
      }
      .category-wrapper {
        margin-bottom: 10px;
        .card-twelve-organizer {
          font-size: 14px;
        }
        .dots {
          padding: 0 10px;
          font-size: 20px;
        }
        .card-twelve-category {
          font-size: 12px;
          @media only screen and (max-width: 1024px) {
            font-size: 10px;
          }
          &:last-child {
            span {
              display: none;
            }
          }
          span {
            padding: 0 8px;
            font-size: 12px;
          }
        }
      }
      .title-wrapper {
        &:hover {
          color: white !important;
        }
        .card-twelve-title {
          margin-bottom: 16px;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
        .card-twelve-desc {
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
      .schedule-wrapper {
        .box {
          width: 50%;
          svg {
            width: 15px;
            height: 15px;
            margin-right: 5px;
          }
          .location,
          .date {
            font-size: 12px;
          }
          &.card-twelve-location {
            justify-content: flex-end;
            .location {
              width: inherit;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
  // card-twelve BACKGROUND WHITE
  .card-twelve-white {
    width: 100%;
    // min-width: 300px;
    background: white;
    height: fit-content;
    border-radius: 6px;
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
      padding: 15px;
    }
    .img-wrapper {
      height: auto;
    }
    .card-twelve-body {
      padding: 16px;
      font-size: 12px;
      min-height: 220px;
      @media only screen and (max-width: 1024px) {
        padding: 0;
        min-height: unset;
      }
      .category-wrapper {
        color: #4a25aa;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 4px;
        }
        .card-twelve-organizer {
          font-size: 12px;
        }
        .dots {
          padding: 0 10px;
          font-size: 20px;
        }
        .card-twelve-category {
          display: flex;
          align-items: center;
          font-size: 12px;
          @media only screen and (max-width: 1024px) {
            font-size: 10px;
            white-space: nowrap;
          }
          &:last-child {
            span {
              display: none;
            }
          }
          span {
            padding: 0 8px;
            font-size: 12px;
          }
        }
      }
      .schedule-wrapper {
        @media only screen and (max-width: 1024px) {
          display: none !important;
        }
      }
      .title-wrapper {
        .card-twelve-title {
          margin-bottom: 16px;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          color: black;
          @media only screen and (max-width: 1024px) {
            margin-bottom: 8px;
          }
        }
        .card-twelve-desc {
          margin-bottom: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          color: black;
        }
      }
      svg {
        margin-right: 5px;
      }
      .schedule-wrapper {
        .box {
          width: 50%;
          color: #616161;
          &.card-twelve-location {
            justify-content: flex-end;
            .location {
              width: inherit;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
    .content-wrapper-bottom {
      @media only screen and (max-width: 1024px) {
        display: flex !important;
        .img-wrapper {
          width: 40%;
          border-radius: 3px;
          overflow: hidden;
        }
        .schedule-wrapper {
          width: 60%;
          padding-left: 15px;
          flex-direction: column;
          justify-content: center !important;
          .card-twelve-datetime {
            margin-bottom: 5px;
          }
          svg {
            margin-right: 5px;
          }
          .card-twelve-location {
            .location {
              width: fit-content;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
    }
  }
}
</style>
