<template>
  <div class="card-eleven">
    <Link :to="to" class="img-wrapper">
      <ImageResponsive
        v-if="image"
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
        widthMobile="500"
        heightMobile="281"
        class="card-eleven-img-top"
        alt=""
      />
      <!--      <div class="ticket">-->
      <!--        <Icons name="ticket" />-->
      <!--        <div>{{ getPrice === "GRATIS" ? "Gratis" : "Berbayar" }}</div>-->
      <!--      </div>-->
    </Link>
    <div class="card-eleven-body">
      <div class="card-eleven-body-header">
        <div
          class="card-eleven-category"
          v-for="category in categories.length > 0
            ? categories.length > 2
              ? categories.slice(0, 2)
              : categories
            : [
                {
                  id: '1',
                  title: 'Uncategorized',
                },
              ]"
          :key="category.id"
        >
          {{ category.title }} <span>&bull;</span>
        </div>
      </div>
      <Link :to="to" class="card-eleven-title" :class="{ end: eventOver }">
        {{ title }}
      </Link>
      <div class="card-eleven-text">
        {{
          description
            ? description
            : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
        }}
      </div>
      <div class="card-eleven-datetime">
        <Icons name="calendar" color="#616161" />
        <div class="datetime">
          {{ $moment(datetime).utc().format("DD/MM/YYYY") }}
        </div>
      </div>
      <div class="card-eleven-location">
        <Icons name="location" color="#616161" />
        <div class="location">
          {{ location }}
        </div>
      </div>
      <div class="card-eleven-body-footer">
        <div class="card-eleven-price">
          <div class="price" :class="{ end: eventOver }">{{ getPrice }}</div>
          <div class="discount" v-if="price">{{ changeToRupiah(price) }}</div>
        </div>
        <Link
          :to="to"
          class="btn-daftar-event"
          :class="{ disable: checkDate }"
          >{{ eventOver ? "SELESAI" : "DAFTAR" }}</Link
        >
      </div>
    </div>
  </div>
</template>

<script>
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    Icons,
    ImageResponsive
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: String,
      default: "",
    },
    categories: {
      type: [Object, String, Array],
      default: "",
    },
    location: {
      type: [Object, String],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    datetime: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: null,
    },
    discount: {
      type: Number,
      default: null,
    },
    to: {
      type: [Object, String],
      default: "",
    },
    startEvent: {
      type: [Object, String],
      default: null,
    },
    endEvent: {
      type: [Object, String],
      default: null,
    },
    startRegis: {
      type: [Object, String],
      default: null,
    },
    endRegis: {
      type: [Object, String],
      default: null,
    },
  },
  computed: {
    getPrice() {
      let price = this.price;
      let discountInPercent = this.discount;
      let realPrice = (price / 100) * discountInPercent;
      if (realPrice === price || realPrice === 0) {
        return "GRATIS";
      } else {
        return this.changeToRupiah(realPrice);
      }
    },
    checkDate() {
      const startRegistrationDate = this.$moment(this.startRegis)
        .utc()
        .format();
      const endRegistrationDate = this.$moment(this.endRegis).utc().format();
      if (
        endRegistrationDate > this.$moment().format() &&
        startRegistrationDate < this.$moment().format()
      ) {
        return false;
      } else {
        return true;
      }
    },
    eventOver() {
      const endDate = this.$moment(this.endEvent).utc().format();
      if (endDate < this.$moment().format()) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    changeToRupiah(data) {
      let bilangan = data;
      let reverse = bilangan.toString().split("").reverse().join(""),
        ribuan = reverse.match(/\d{1,3}/g);
      ribuan = ribuan.join(".").split("").reverse().join("");
      return "Rp. " + ribuan;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-eleven {
  border-radius: 6px;
  overflow: hidden;
  width: 350px;
  height: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background: white;
  @media only screen and (max-width: 1024px) {
    width: 320px;
    height: fit-content;
  }
  .img-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    // .image {
    //   object-fit: cover;
    //   width: 100%;
    //   height: 100%;
    //   img {
    //     width: 100%;
    //     height: 100% !important;
    //     object-fit: cover;
    //   }
    // }
    .ticket {
      font-size: 12px;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      position: absolute;
      bottom: 10px;
      left: 10px;
      color: white;
      background: rgb(182, 15, 127);
      background: linear-gradient(
        90deg,
        rgba(182, 15, 127, 1) 0%,
        rgba(74, 37, 170, 1) 100%
      );
      border-radius: 5px;
      svg {
        margin-right: 5px;
      }
    }
  }
  .card-eleven-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    min-height: 240px;
    .card-eleven-body-header {
      display: flex;
      margin-bottom: 10px;
      font-size: 14px;
      .card-eleven-category {
        font-size: 12px;
        color: #4a25aa;
        @media only screen and (max-width: 1024px) {
          white-space: nowrap;
        }
        &:last-child {
          span {
            display: none;
          }
        }
        span {
          padding: 0 8px;
          font-size: 12px;
          @media only screen and (max-width: 1024px) {
            padding: 0 4px;
          }
        }
      }
    }
    .card-eleven-title {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      color: #0c0b0d;
      &.end {
        color: #616161;
      }
    }
    .card-eleven-text {
      font-size: 12px;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      color: #616161;
    }
    .card-eleven-datetime {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      svg {
        width: 16px;
        height: 16px;
      }
      .datetime {
        font-size: 12px;
        color: #757575;
      }
    }
    .card-eleven-location {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      svg {
        width: 16px;
        height: 16px;
      }
      .location {
        font-size: 12px;
        color: #757575;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
    .card-eleven-body-footer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .card-eleven-price {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .price {
          font-size: 16px;
          font-weight: bold;
          margin-right: 20px;
          color: #4a25aa;
          &.end {
            color: #616161;
          }
        }
        .discount {
          font-size: 12px;
          font-weight: bold;
          text-decoration: line-through;
          color: #616161;
        }
      }
      .btn-daftar-event {
        font-size: 12px;
        color: white;
        text-decoration: none;
        background: #4a25aa;
        padding: 5px 15px;
        border-radius: 5px;
        &:hover {
          color: white !important;
        }
        &.disable {
          pointer-events: none;
          background: #e0e0e0;
          color: #9e9e9e;
        }
      }
    }
    svg {
      margin-right: 5px;
    }
  }
}
</style>
