var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-two"},[_c('div',{staticClass:"card-two-thumbnail"},[_c('Link',{attrs:{"to":_vm.to,"aria-label":_vm.title}},[(_vm.image)?_c('ImageResponsive',{staticClass:"card-two-image",attrs:{"imageUrl":_vm.image,"imageUrlMobile":_vm.image,"fromUrl":_vm.imageFromUrl}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"card-two-duration",class:{ backgroundAds: _vm.isAdvertorial }},[(_vm.isAdvertorial)?_c('p',{staticClass:"advertorial"},[_vm._v("Ad")]):(_vm.isText)?_c('div',{staticClass:"article-reguler"},[_c('i',{staticClass:"bi bi-book-half"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("TEKS")])]):_c('p',{staticClass:"duration"},[_vm._v(_vm._s(_vm.duration))])])],1),_vm._v(" "),_c('div',{staticClass:"card-two-body"},[_c('div',{staticClass:"card-two-body-header"},[(_vm.withCategory)?_c('div',{staticClass:"card-two-channel-category"},[_c('Link',{staticClass:"channel",class:{ small: _vm.mobileCardDouble },attrs:{"to":{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: _vm.programSlug,
              slugProgram: _vm.channelSlug,
            },
            query: {
              type: 'highlights',
            },
          }}},[_vm._v(_vm._s(_vm.channel))]),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.categorySlug),expression:"categorySlug"}],class:{ small: _vm.mobileCardDouble }},[_vm._v("•")]),_vm._v(" "),_c('Link',{directives:[{name:"show",rawName:"v-show",value:(_vm.categorySlug),expression:"categorySlug"}],staticClass:"category",class:{ small: _vm.mobileCardDouble },attrs:{"to":{
            name: 'interest',
            query: {
              type: _vm.categorySlug,
            },
          }}},[_vm._v(_vm._s(_vm.category))])],1):_vm._e(),_vm._v(" "),_c('Link',{staticClass:"card-two-title",class:{ custTitle: _vm.customTitle },attrs:{"to":_vm.to}},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")])],1),_vm._v(" "),(_vm.withDate)?_c('div',{staticClass:"card-two-body-footer"},[_vm._v("\n      "+_vm._s(_vm.$moment(_vm.date).utc().format("DD MMMM YYYY hh:mm"))+" WIB\n    ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }