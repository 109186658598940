<template>
  <Link :to="to" class="card-twenty-four">
    <div class="card-twenty-four-thumbnail">
      <ImageResponsive
        v-if="image"
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
        class="card-twenty-four-image"
      />
      <div
        class="card-twenty-four-duration"
        :class="{ backgroundAds: isAdvertorial }"
      >
        <p class="advertorial" v-if="isAdvertorial">Ad</p>
        <div class="article-reguler" v-else-if="isText">
          <i class="bi bi-book-half"></i>
          <p class="text">TEKS</p>
        </div>
        <p class="duration" v-else>{{ duration }}</p>
      </div>
    </div>
    <div class="card-twenty-four-body">
      <div class="card-twenty-four-body-header">
        <div class="card-twenty-four-channel-category">
          <Link
            :to="{
              name: 'program-childBrand-slugProgram',
              params: {
                childBrand: programSlug,
                slugProgram: channelSlug,
              },
              query: {
                type: 'highlights',
              },
            }"
            class="channel"
            :class="{ small: mobileCardDouble }"
            >{{ channel }}</Link
          >
          <span v-show="categorySlug" :class="{ small: mobileCardDouble }"
            >&bull;</span
          >
          <Link
            v-show="categorySlug"
            :to="{
              name: 'interest',
              query: {
                type: categorySlug,
              },
            }"
            class="category"
            :class="{ small: mobileCardDouble }"
            >{{ category }}</Link
          >
        </div>
        <div class="card-twenty-four-title">
          {{ title }}
        </div>
      </div>
      <div class="card-twenty-four-body-footer" v-if="withDate">
        <Link
          v-show="categorySlug"
          :to="{
            name: 'interest',
            query: {
              type: categorySlug,
            },
          }"
          class="category"
          :class="{ small: mobileCardDouble }"
          >{{ category }}</Link
        >
        <div class="date">
          {{ $moment(date).utc().format("DD MMMM YYYY hh:mm") }} WIB
        </div>
      </div>
    </div>
  </Link>
</template>

<script>
import Link from "@/components/Link";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    ImageResponsive,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    duration: {
      type: [Number, String],
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    channelSlug: {
      type: String,
      default: "",
    },
    programSlug: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    categorySlug: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: "",
    },
    isText: {
      type: Boolean,
      default: false,
    },
    isAdvertorial: {
      type: Boolean,
      default: false,
    },
    withDate: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "",
    },
    mobileCardDouble: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-twenty-four {
  width: 250px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .card-twenty-four-thumbnail {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
      border-radius: 6px;
      width: 25vw;
      height: 25vw;
    }
    .card-twenty-four-image {
      width: 100%;
      height: 100%;
      ::v-deep img {
        object-fit: cover;
      }
    }
    .card-twenty-four-duration {
      background: rgba(0, 0, 0, 0.7);
      padding: 3px 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      @media only screen and (max-width: 1024px) {
        border-top-left-radius: 6px;
      }
      .article-reguler {
        display: flex;
        align-items: center;
        .bi-book-half {
          width: 16px;
          height: auto;
          margin-right: 2px;
          color: #ffffff;
        }
        .text {
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
          margin: 0;
        }
      }
      &.backgroundAds {
        background: rgba(128, 128, 128, 0.7);
        padding: 3px 8px;
      }
      .advertorial {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .duration {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
    }
  }
  .card-twenty-four-body {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      padding: 0 0 0 16px;
      height: 25vw;
      justify-content: unset;
      width: calc(100% - 25vw);
    }
    .card-twenty-four-body-header {
      .card-twenty-four-channel-category {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        @media only screen and (max-width: 1024px) {
          display: none;
        }
        .channel,
        .category {
          font-size: 12px;
          font-weight: 400;
          color: #4a25aa;
          margin: 0;
          &.small {
            @media only screen and (max-width: 1024px) {
              font-size: 10px;
            }
          }
        }
        span {
          font-size: 12px;
          padding: 0 10px;
          @media only screen and (max-width: 1024px) {
            font-size: 10px;
          }
          &.small {
            @media only screen and (max-width: 1024px) {
              font-size: 8px;
              padding: 0 5px;
            }
          }
        }
      }
      .card-twenty-four-title {
        font-size: 16px;
        font-weight: 500;
        color: #0c0b0d;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        @media only screen and (max-width: 1024px) {
          font-size: 14px;
          line-height: 18px;
          -webkit-line-clamp: 4;
        }
      }
    }
    .card-twenty-four-body-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      .category {
        display: none;
        @media only screen and (max-width: 1024px) {
          display: block;
          font-size: 12px;
          font-weight: 400;
          color: #4a25aa;
          margin: 0;
          padding-right: 5px;
          &.small {
            font-size: 10px;
          }
        }
      }
      .date {
        font-size: 10px;
        color: #616161;
        @media only screen and (max-width: 1024px) {
          position: relative;
          padding-left: 7px;
          &:after {
            content: " ";
            position: absolute;
            border-left: 1px solid #616161;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height: 60%;
            margin-top: auto;
            margin-bottom: auto;
          }
        }
      }
    }
  }
}
</style>
