<template>
  <Link class="card-four" :to="to">
    <div class="card-four__logo-channel">
      <ImageResponsiveSmall
        v-if="imageLogo"
        :imageUrl="imageLogo"
        :fromUrl="imageLogoFromUrl"
      />
    </div>
    <div class="card-four__thumbnail">
      <ImageResponsive
        v-if="image"
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
        class="card-four__thumbnail__image"
      />
      <div
        class="card-four__thumbnail__duration"
        :class="{ backgroundAds: isAdvertorial }"
      >
        <p class="advertorial" v-if="isAdvertorial">Ad</p>
        <div class="article-reguler" v-else-if="isText">
          <!-- <Icons name="book" /> -->
          <i class="bi bi-book-half"></i>
          <p class="text">TEKS</p>
        </div>
        <p class="duration" v-else>{{ duration }}</p>
      </div>
    </div>
    <div class="card-four__body">
      <div class="card-four__body__channel-category">
        <Link
          :to="{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: programSlug,
              slugProgram: channelSlug,
            },
            query: {
              type: 'highlights',
            },
          }"
          class="channel"
          >{{ channel }}</Link
        >
        <span v-show="categorySlug">&bull;</span>
        <Link
          v-show="categorySlug"
          :to="{
            name: 'interest',
            query: {
              type: categorySlug,
            },
          }"
          class="category"
          >{{ category }}</Link
        >
      </div>
      <div class="card-four__body__title">
        {{ title }}
      </div>
    </div>
  </Link>
</template>

<script>
import ImageResponsiveSmall from "@/components/ImageResponsiveSmall";
import ImageResponsive from "@/components/ImageResponsive";
import Link from "@/components/Link";

export default {
  components: {
    Link,
    ImageResponsive,
    ImageResponsiveSmall,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    imageLogo: {
      type: String,
      default: "",
    },
    imageLogoFromUrl: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: [Number, String],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    categorySlug: {
      type: String,
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    channelSlug: {
      type: String,
      default: "",
    },
    programSlug: {
      type: String,
      default: "",
    },
    to: {
      type: [Object, String],
      default: "",
    },
    isText: {
      type: Boolean,
      default: false,
    },
    isAdvertorial: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-four {
  width: 250px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  &__logo-channel {
    margin-bottom: 20px;
  }
  &__thumbnail {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
      border-radius: 6px;
    }
    &__image {
      width: 100%;
      height: auto;
    }
    &__duration {
      background: rgba(0, 0, 0, 0.7);
      padding: 3px 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      @media only screen and (max-width: 1024px) {
        border-top-left-radius: 6px;
      }
      .article-reguler {
        display: flex;
        align-items: center;
        .bi-book-half {
          width: 16px;
          height: auto;
          margin-right: 2px;
          color: #ffffff;
        }
        .text {
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
          margin: 0;
        }
      }
      &.backgroundAds {
        background: rgba(128, 128, 128, 0.7);
        padding: 3px 8px;
      }
      .advertorial {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .duration {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
    }
  }
  &__body {
    padding: 16px 0 0;
    &__channel-category {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .channel,
      .category {
        font-size: 12px;
        font-weight: 400;
        color: #4a25aa;
        margin: 0;
      }
      span {
        font-size: 12px;
        padding: 0 10px;
      }
    }
    &__title {
      font-size: 16px;
      font-weight: 500;
      color: black;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }
}
</style>
