<template>
  <div class="col-md-12">
    <div class="card-fourteen">
      <Link :to="to" class="card-fourteen-thumbnail-wrapper is-desktop">
        <ImageResponsive
          v-if="image"
          :imageUrl="image"
          :imageUrlMobile="image"
          :fromUrl="imageFromUrl"
          class="card-fourteen-thumbnail"
          alt=""
        />
        <div class="card-fourteen-ticket">
          <Icons name="ticket" />
          <p class="ticket-price">{{ ticket }}</p>
        </div>
      </Link>
      <div class="card-fourteen-content">
        <div class="card-fourteen-copy-left">
          <p class="card-fourteen-category">
            {{ typeof category === "object" ? category.name : category }}
          </p>
          <Link v-if="title" :to="to" class="card-fourteen-title">
            {{ title }}
          </Link>
          <p class="card-fourteen-desc">
            {{ description }}
          </p>
          <div class="d-flex">
            <div class="card-fourteen-datetime">
              <Icons name="calendar" />
              <p class="date">{{ datetime }}</p>
            </div>
            <div class="card-fourteen-class-type">
              <Icons name="tipe-kelas" />
              <p
                class="card-fourteen-class-type-text class-type"
                v-if="classType"
              >
                {{ typeof classType === "object" ? classType.name : classType }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="copy-wrapper-side d-flex flex-column align-items-end justify-content-between"
        >
          <div
            class="card-fourteen-price d-flex flex-column align-items-end justify-content-between"
          >
            <p class="price">{{ getPrice }}</p>
            <p class="discount">{{ changeToRupiah(price) }}</p>
          </div>
          <Link v-if="title" :to="to" class="btn-ikuti-kelas"
            >IKUTI KELAS</Link
          >
        </div>
      </div>
      <div class="copy-wrapper-bottom is-mobile">
        <div class="card-fourteen-thumbnail-wrapper">
          <ImageResponsive
            v-if="image"
            :imageUrl="image"
            :imageUrlMobile="image"
            :fromUrl="imageFromUrl"
            alt=""
          />
          <div class="card-fourteen-ticket">
            <Icons name="ticket" />
            <p class="ticket-price">{{ ticket }}</p>
          </div>
        </div>
        <div class="copy-wrapper d-flex flex-column">
          <div class="card-fourteen-class-type d-flex">
            <Icons name="tipe-kelas" />
            <p class="class-type" v-if="classType">
              {{ typeof classType === "object" ? classType.name : classType }}
            </p>
          </div>
          <div class="card-fourteen-datetime d-flex">
            <Icons name="calendar" />
            <p class="date">{{ datetime }}</p>
          </div>
          <div class="card-fourteen-price d-flex">
            <p class="price">{{ getPrice }}</p>
            <p class="discount">{{ changeToRupiah(price) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    Icons,
    ImageResponsive
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    ticket: {
      type: String,
      default: "Gratis",
    },
    category: {
      type: [Object, String],
      default: "",
    },
    classType: {
      type: [Object, String],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    datetime: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: null,
    },
    discount: {
      type: Number,
      default: null,
    },
    to: {
      type: [Object, String],
      default: "",
    },
  },
  computed: {
    totalDiscount() {
      let price = this.price;
      let discountInPercent = this.discount;
      let discount = (price / 100) * discountInPercent;
      return this.changeToRupiah(discount);
    },
    getPrice() {
      let price = this.price;
      let discountInPercent = this.discount;
      let discount = (price / 100) * discountInPercent;
      let realPrice = price - discount;
      if (realPrice === 0) {
        return "GRATIS";
      } else {
        return this.changeToRupiah(realPrice);
      }
    },
  },
  methods: {
    changeToRupiah(data) {
      let bilangan = data;
      let reverse = bilangan.toString().split("").reverse().join(""),
        ribuan = reverse.match(/\d{1,3}/g);
      ribuan = ribuan.join(".").split("").reverse().join("");
      return "Rp. " + ribuan;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-fourteen {
  border: 1px solid rgba(0, 0, 0, 0.125);
  background: white;
  overflow: hidden;
  width: 75%;
  height: fit-content;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 1024px) {
    padding: 15px;
    width: 100%;
    flex-direction: column !important;
  }
  .card-fourteen-thumbnail-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 40%;
    height: auto;
    overflow: hidden;
    .card-fourteen-ticket {
      display: flex;
      align-items: center;
      padding: 4px 8px;
      position: absolute;
      bottom: 10px;
      left: 10px;
      color: white;
      background: rgb(182, 15, 127);
      background: linear-gradient(
        90deg,
        rgba(182, 15, 127, 1) 0%,
        rgba(74, 37, 170, 1) 100%
      );
      border-radius: 5px;
      svg {
        margin-right: 5px;
      }
      .ticket-price {
        margin: 0;
      }
    }
  }
  .card-fourteen-content {
    width: 60%;
    font-size: 12px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1024px) {
      width: 100%;
      flex-direction: row;
      padding: 0;
      align-items: center;
    }
    .card-fourteen-copy-left {
      width: 70%;
      align-self: center;
      @media only screen and (max-width: 1024px) {
        width: 75%;
        padding: 0;
      }
      .card-fourteen-category {
        font-size: 18px;
        margin-bottom: 4px;
        font-weight: 500;
        color: #4a25aa;
        @media only screen and (max-width: 1024px) {
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
      .card-fourteen-title {
        font-size: 20px;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        color: black;
        @media only screen and (max-width: 1024px) {
          font-size: 16px;
          line-height: 18px;
        }
      }
      .card-fourteen-desc {
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        color: #616161;
        @media only screen and (max-width: 1024px) {
          display: none;
        }
      }
      .card-fourteen-datetime,
      .card-fourteen-class-type {
        display: flex;
        align-items: center;
        margin-right: 10px;
        color: #616161;
        @media only screen and (max-width: 1024px) {
          display: none !important;
        }
        svg {
          width: 20px;
          height: 20px;
        }
        .class-type,
        .date {
          font-size: 18px;
          margin: 0;
        }
      }
    }
    .copy-wrapper-side {
      width: 30%;
      @media only screen and (max-width: 1024px) {
        width: 25%;
        padding: 0;
      }
      .card-fourteen-price {
        color: #4a25aa;
        @media only screen and (max-width: 1024px) {
          display: none !important;
        }
        .price {
          font-weight: bold;
          font-size: 26px;
          margin: 0;
        }
        .discount {
          font-size: 18px;
          font-weight: bold;
          text-decoration: line-through;
          color: #616161;
          margin: 0;
        }
      }
      .btn-ikuti-kelas {
        background: #4a25aa;
        font-size: 16px;
        padding: 5px 18px;
        color: white;
        border-radius: 5px;
        text-decoration: none;
        @media only screen and (max-width: 1024px) {
          padding: 5px 8px;
          border-radius: 5px;
          font-size: 10px;
        }
        &:hover {
          color: white !important;
        }
      }
    }
    svg {
      margin-right: 5px;
      @media only screen and (max-width: 1024px) {
        margin-right: 5px;
      }
    }
  }
  .copy-wrapper-bottom {
    @media only screen and (max-width: 1024px) {
      display: flex !important;
      flex-direction: row;
    }
    .card-fourteen-thumbnail-wrapper {
      .card-fourteen-ticket {
        @media only screen and (max-width: 1024px) {
          padding: 2px 6px;
          bottom: 8px;
          left: 8px;
          border-radius: 3px;
        }
        .ticket-price {
          @media only screen and (max-width: 1024px) {
            font-size: 10px;
            margin: 0;
          }
        }
        svg {
          @media only screen and (max-width: 1024px) {
            margin-right: 3px;
          }
        }
      }
    }
    .copy-wrapper {
      @media only screen and (max-width: 1024px) {
        width: 60%;
        padding-left: 15px;
        justify-content: center;
      }
      .card-fourteen-datetime,
      .card-fourteen-class-type {
        @media only screen and (max-width: 1024px) {
          margin-bottom: 5px;
          color: #616161;
        }
        svg {
          @media only screen and (max-width: 1024px) {
            width: 15px;
            height: 15px;
            margin-right: 5px;
          }
        }
        .class-type,
        .date {
          @media only screen and (max-width: 1024px) {
            font-size: 12px;
            margin: 0;
          }
        }
      }
      .card-fourteen-price {
        @media only screen and (max-width: 1024px) {
          align-items: flex-end;
          line-height: 20px;
          color: #4a25aa;
        }
        .price {
          @media only screen and (max-width: 1024px) {
            font-weight: bold;
            font-size: 18px;
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
        .discount {
          @media only screen and (max-width: 1024px) {
            font-size: 14px;
            font-weight: bold;
            text-decoration: line-through;
            color: #616161;
            margin: 0;
          }
        }
      }
    }
  }
}
</style>
