<template>
  <div class="card-two">
    <div class="card-two-thumbnail">
      <Link :to="to" :aria-label="title">
        <ImageResponsive
          v-if="image"
          :imageUrl="image"
          :imageUrlMobile="image"
          :fromUrl="imageFromUrl"
          class="card-two-image"
        />
      </Link>
      <div class="card-two-duration" :class="{ backgroundAds: isAdvertorial }">
        <p class="advertorial" v-if="isAdvertorial">Ad</p>
        <div class="article-reguler" v-else-if="isText">
          <!-- <Icons name="book" /> -->
          <i class="bi bi-book-half"></i>
          <p class="text">TEKS</p>
        </div>
        <p class="duration" v-else>{{ duration }}</p>
      </div>
    </div>
    <div class="card-two-body">
      <div class="card-two-body-header">
        <div class="card-two-channel-category" v-if="withCategory">
          <Link
            :to="{
              name: 'program-childBrand-slugProgram',
              params: {
                childBrand: programSlug,
                slugProgram: channelSlug,
              },
              query: {
                type: 'highlights',
              },
            }"
            class="channel"
            :class="{ small: mobileCardDouble }"
            >{{ channel }}</Link
          >
          <span v-show="categorySlug" :class="{ small: mobileCardDouble }"
            >&bull;</span
          >
          <Link
            v-show="categorySlug"
            :to="{
              name: 'interest',
              query: {
                type: categorySlug,
              },
            }"
            class="category"
            :class="{ small: mobileCardDouble }"
            >{{ category }}</Link
          >
        </div>
        <Link
          :to="to"
          class="card-two-title"
          :class="{ custTitle: customTitle }"
        >
          {{ title }}
        </Link>
      </div>
      <div class="card-two-body-footer" v-if="withDate">
        {{ $moment(date).utc().format("DD MMMM YYYY hh:mm") }} WIB
      </div>
    </div>
  </div>
</template>

<script>
import Link from "@/components/Link";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    ImageResponsive,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    duration: {
      type: [Number, String],
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    channelSlug: {
      type: String,
      default: "",
    },
    programSlug: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    categorySlug: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: "",
    },
    isText: {
      type: Boolean,
      default: false,
    },
    isAdvertorial: {
      type: Boolean,
      default: false,
    },
    withDate: {
      type: Boolean,
      default: false,
    },
    withCategory: {
      type: Boolean,
      default: true,
    },
    customTitle: {
      type: Boolean,
      default: false,
    },
    date: {
      type: String,
      default: "",
    },
    mobileCardDouble: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-two {
  width: 250px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  .card-two-thumbnail {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
      border-radius: 6px;
    }
    .card-two-image {
      width: 100%;
      height: auto;
    }
    .card-two-duration {
      background: rgba(0, 0, 0, 0.7);
      padding: 3px 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      @media only screen and (max-width: 1024px) {
        border-top-left-radius: 6px;
      }
      .article-reguler {
        display: flex;
        align-items: center;
        .bi-book-half {
          width: 16px;
          height: auto;
          margin-right: 2px;
          color: #ffffff;
        }
        .text {
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
          margin: 0;
        }
      }
      &.backgroundAds {
        background: rgba(128, 128, 128, 0.7);
        padding: 3px 8px;
      }
      .advertorial {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .duration {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
    }
  }
  .card-two-body {
    padding: 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .card-two-body-header {
      .card-two-channel-category {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .channel,
        .category {
          font-size: 12px;
          font-weight: 400;
          color: #4a25aa;
          margin: 0;
          &.small {
            @media only screen and (max-width: 1024px) {
              font-size: 10px;
            }
          }
        }
        span {
          font-size: 12px;
          padding: 0 10px;
          @media only screen and (max-width: 1024px) {
            font-size: 10px;
          }
          &.small {
            @media only screen and (max-width: 1024px) {
              font-size: 8px;
              padding: 0 5px;
            }
          }
        }
      }
      .card-two-title {
        font-size: 16px;
        font-weight: 500;
        color: #0c0b0d;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        &.custTitle {
          font-size: 14px;
          line-height: 130%;
          -webkit-line-clamp: 3;
          margin: 0;
        }
      }
    }
    .card-two-body-footer {
      font-size: 10px;
      color: #616161;
    }
  }
}
</style>
