<template>
  <Link :to="to" class="card-ten">
    <div class="card-ten__thumbnail">
      <ImageResponsive
        v-if="image"
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
        class="card-ten__thumbnail__image"
      />
      <div
        class="card-ten__thumbnail__duration"
        :class="{ backgroundAds: isAdvertorial }"
      >
        <p class="advertorial" v-if="isAdvertorial">Ad</p>
        <div class="article-reguler" v-else-if="isText">
          <!-- <Icons name="book" /> -->
          <i class="bi bi-book-half"></i>
          <p class="text">TEKS</p>
        </div>
        <p class="duration" v-else>{{ duration }}</p>
      </div>
    </div>
    <div class="card-ten__body">
      <div class="card-ten__body__channel-category">
        <Link
          :to="{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: programSlug,
              slugProgram: channelSlug,
            },
            query: {
              type: 'highlights',
            },
          }"
          class="channel"
          >{{ channel }}</Link
        >
        <span v-show="categorySlug">&bull;</span>
        <Link
          v-show="categorySlug"
          :to="{
            name: 'interest',
            query: {
              type: categorySlug,
            },
          }"
          class="category"
          >{{ category }}</Link
        >
      </div>
      <div class="channel-category channel is-mobile">{{ channel }}</div>
      <div class="card-ten__body__title">
        {{ title }}
      </div>
      <div class="channel-category category is-mobile">{{ category }}</div>
    </div>
  </Link>
</template>

<script>
import Link from "@/components/Link";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    ImageResponsive,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    duration: {
      type: [Number, String],
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    channelSlug: {
      type: String,
      default: "",
    },
    programSlug: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    categorySlug: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: "",
    },
    isText: {
      type: Boolean,
      default: false,
    },
    isAdvertorial: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-ten {
  width: 250px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
  &__thumbnail {
    position: relative;
    @media only screen and (max-width: 1024px) {
      height: 88px;
      width: fit-content;
    }
    &__image {
      ::v-deep img {
        border-radius: 10px;
        @media only screen and (max-width: 1024px) {
          height: 100%;
          width: auto;
          border-radius: 6px;
        }
      }
    }
    &__duration {
      background: rgba(0, 0, 0, 0.7);
      padding: 3px 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      @media only screen and (max-width: 1024px) {
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .article-reguler {
        display: flex;
        align-items: center;
        .bi-book-half {
          width: 16px;
          height: auto;
          margin-right: 2px;
          color: #ffffff;
        }
        .text {
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
          margin: 0;
        }
      }
      &.backgroundAds {
        background: rgba(128, 128, 128, 0.7);
        padding: 3px 8px;
      }
      .advertorial {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .duration {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
    }
  }
  &__body {
    padding: 16px 0;
    @media only screen and (max-width: 1024px) {
      width: -webkit-fill-available;
      padding: 0 0 0 16px;
      align-self: center;
    }
    .channel-category {
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
        font-weight: 400;
        color: #4a25aa;
      }
      &.channel {
        @media only screen and (max-width: 1024px) {
          margin-bottom: 8px;
        }
      }
    }
    &__channel-category {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      @media only screen and (max-width: 1024px) {
        display: none;
      }
      .channel,
      .category {
        font-size: 12px;
        font-weight: 400;
        color: #4a25aa;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          margin-bottom: 8px;
        }
      }
      span {
        font-size: 12px;
        padding: 0 10px;
      }
    }
    &__title {
      font-size: 16px;
      font-weight: 500;
      color: black;
      margin-bottom: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      @media only screen and (max-width: 1024px) {
        margin-bottom: 8px;
        font-size: 14px;
      }
    }
  }
}
</style>
