<template>
  <div class="card-etalase">
    <ImageResponsive
      v-if="image"
      :imageUrl="image"
      :fromUrl="imageFromUrl"
      width="90"
      height="90"
      widthMobile="90"
      heightMobile="90"
      class="image-etalase"
    />
    <Link :to="to" class="title-etalase">
      {{ title }}
    </Link>
    <div class="bottom-card">
      <p class="price-etalase">{{ changeToRupiah(price) }}</p>
      <a :href="to" target="_blank" rel="noopener" class="btn-etalase">{{
        btnName
      }}</a>
    </div>
  </div>
</template>

<script>
import Link from "@/components/Link";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    ImageResponsive,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    to: {
      type: [Object, String],
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    btnName: {
      type: String,
      default: "",
    },
  },
  methods: {
    changeToRupiah(number) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }).format(number);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-etalase {
  min-width: 180px;
  min-height: 250px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;
  background: #ffffff;
  padding: 8px;
  border-radius: 4px;
  @media only screen and (max-width: 1024px) {
    min-width: 150px;
  }
  .image-etalase {
    width: 100%;
    height: auto;
  }
  .title-etalase {
    font-size: 14px;
    color: #000000;
    margin-bottom: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    @media only screen and (max-width: 1024px) {
      margin: 0;
    }
  }
  .bottom-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 1024px) {
      flex-wrap: wrap;
      gap: 12px;
    }
    .price-etalase {
      font-size: 12px;
      font-weight: 700;
      color: #4a25aa;
      margin: 0;
    }
    .btn-etalase {
      background: #4a25aa;
      padding: 4px 16px;
      border-radius: 4px;
      font-size: 10px;
      font-weight: 500;
      color: #fafafa;
      white-space: nowrap;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &:hover {
        color: #fafafa !important;
      }
    }
  }
}
</style>
