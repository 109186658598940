<template>
  <div class="col-md-12 card-thirteen-wrapper">
    <div class="card-thirteen">
      <Link
        v-if="title"
        :to="{
          name: 'event-organizer-slug',
          params: {
            organizer: organizer,
            slug: slug,
          },
        }"
        class="card-thirteen-thumbnail-wrapper is-desktop"
      >
        <ImageResponsive
          v-if="image"
          :imageUrl="image"
          :imageUrlMobile="image"
          :fromUrl="imageFromUrl"
          class="card-thirteen-thumbnail"
          alt=""
        />
      </Link>
      <div class="card-thirteen-content">
        <div class="card-thirteen-copy-left">
          <div class="category-wrapper">
            <p
              class="card-thirteen-category"
              v-for="category in categories.length > 0
                ? categories.length > 2
                  ? categories.slice(0, 2)
                  : categories
                : [
                    {
                      id: '1',
                      title: 'Uncategorized',
                    },
                  ]"
              :key="category.id"
            >
              {{ category.title }} <span>&bull;</span>
            </p>
          </div>
          <Link v-if="title" :to="to" class="card-thirteen-title">
            {{ title }}
          </Link>
          <p class="card-thirteen-desc">
            {{
              description
                ? description
                : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"
            }}
          </p>
          <div class="d-flex">
            <div class="box card-thirteen-datetime">
              <Icons name="calendar" color="#616161" />
              <p class="date">{{ $moment(datetime).format("DD/MM/YYYY") }}</p>
            </div>
            <div class="box card-thirteen-location">
              <Icons name="location" color="#616161" />
              <p class="location">
                {{ location }}
              </p>
            </div>
          </div>
        </div>
        <div
          class="copy-wrapper-side d-flex flex-column align-items-end justify-content-between"
        >
          <div
            class="card-thirteen-price d-flex flex-column align-items-end justify-content-between"
          >
            <p class="price">{{ getPrice }}</p>
            <p class="discount" v-show="price !== 0">
              {{ changeToRupiah(price) }}
            </p>
          </div>
          <Link
            v-if="title"
            :to="to"
            class="btn-ikuti-kelas"
            :class="{ disable: !checkDate }"
            >DAFTAR</Link
          >
        </div>
      </div>
      <div class="copy-wrapper-bottom is-mobile">
        <div class="card-thirteen-thumbnail-wrapper">
          <ImageResponsive
            v-if="image"
            :imageUrl="image"
            :imageUrlMobile="image"
            :fromUrl="imageFromUrl"
            alt=""
          />
        </div>
        <div class="copy-wrapper d-flex flex-column">
          <div class="box card-thirteen-datetime d-flex">
            <Icons name="calendar" color="#616161" />
            <p class="date">{{ $moment(datetime).format("DD/MM/YYYY") }}</p>
          </div>
          <div class="box card-thirteen-location d-flex">
            <Icons name="location" color="#616161" />
            <p class="location" v-if="location">
              {{ location }}
            </p>
          </div>
          <div class="card-thirteen-price d-flex">
            <p class="price">{{ getPrice }}</p>
            <p class="discount" v-show="price !== 0">
              {{ changeToRupiah(price) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    Icons,
    ImageResponsive
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    organizer: {
      type: String,
      default: "",
    },
    categories: {
      type: [Object, String, Array],
      default: "",
    },
    location: {
      type: [Object, String],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    datetime: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: null,
    },
    discount: {
      type: Number,
      default: null,
    },
    classType: {
      type: Object,
      default: () => {},
    },
    to: {
      type: [Object, String],
      default: "",
    },
    startEvent: {
      type: [Object, String],
      default: null,
    },
    endEvent: {
      type: [Object, String],
      default: null,
    },
    startRegis: {
      type: [Object, String],
      default: null,
    },
    endRegis: {
      type: [Object, String],
      default: null,
    },
  },
  computed: {
    totalDiscount() {
      let price = this.price;
      let discountInPercent = this.discount;
      let discount = (price / 100) * discountInPercent;
      return this.changeToRupiah(discount);
    },
    getPrice() {
      let price = this.price;
      let discountInPercent = this.discount;
      let discount = (price / 100) * discountInPercent;
      let realPrice = price - discount;
      if (realPrice === 0) {
        return "GRATIS";
      } else {
        return this.changeToRupiah(realPrice);
      }
    },
    checkDate() {
      if (this.startRegis && this.endRegis) {
        const compareDate = this.$moment();
        const startDate = this.$moment(this.startRegis);
        const endDate = this.$moment(this.endRegis);
        const isBetween = compareDate.isBetween(startDate, endDate);
        return isBetween;
      } else {
        return false;
      }
    },
  },
  methods: {
    changeToRupiah(data) {
      let bilangan = data;
      let reverse = bilangan.toString().split("").reverse().join(""),
        ribuan = reverse.match(/\d{1,3}/g);
      ribuan = ribuan.join(".").split("").reverse().join("");
      return "Rp. " + ribuan;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-thirteen-wrapper {
  margin-bottom: 20px;
  @media only screen and (max-width: 1024px) {
    margin-bottom: 10px;
  }
  .card-thirteen {
    border: 1px solid rgba(0, 0, 0, 0.125);
    background: white;
    overflow: hidden;
    width: 75%;
    height: fit-content;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: 1024px) {
      padding: 15px;
      width: 100%;
      flex-direction: column !important;
    }
    .card-thirteen-thumbnail-wrapper {
      position: relative;
      display: flex;
      align-items: center;
      width: 40%;
      height: auto;
      overflow: hidden;
      .card-thirteen-ticket {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: white;
        background: rgb(182, 15, 127);
        background: linear-gradient(
          90deg,
          rgba(182, 15, 127, 1) 0%,
          rgba(74, 37, 170, 1) 100%
        );
        border-radius: 5px;
        svg {
          margin-right: 5px;
        }
        .ticket-price {
          margin: 0;
        }
      }
    }
    .card-thirteen-content {
      width: 60%;
      font-size: 12px;
      padding: 15px;
      display: flex;
      justify-content: space-between;
      @media only screen and (max-width: 1024px) {
        width: 100%;
        flex-direction: row;
        padding: 0;
        align-items: center;
      }
      .card-thirteen-copy-left {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 75%;
        @media only screen and (max-width: 1024px) {
          width: 75%;
          padding: 0;
        }
        .category-wrapper {
          display: flex;
          align-items: center;
          .card-thirteen-category {
            display: flex;
            align-items: center;
            font-size: 16px;
            margin-bottom: 10px;
            font-weight: 500;
            color: #4a25aa;
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
              margin-bottom: 5px;
              white-space: nowrap;
            }
            &:last-child {
              span {
                display: none;
              }
            }
            span {
              padding: 0 8px;
              font-size: 12px;
              @media only screen and (max-width: 1024px) {
                padding: 0 4px;
              }
            }
          }
        }
        .card-thirteen-title {
          font-size: 20px;
          font-weight: 500;
          line-height: 30px;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          color: black;
          @media only screen and (max-width: 1024px) {
            font-size: 16px;
            line-height: 18px;
          }
        }
        .card-thirteen-desc {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          color: #616161;
          @media only screen and (max-width: 1024px) {
            display: none;
          }
        }
        .box {
          display: flex;
          align-items: center;
          margin-right: 10px;
          color: #616161;
          @media only screen and (max-width: 1024px) {
            display: none !important;
          }
          svg {
            width: 20px;
            height: 20px;
          }
          .location,
          .date {
            font-size: 18px;
            margin: 0;
          }
          &.card-thirteen-location {
            .location {
              width: fit-content;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
      }
      .copy-wrapper-side {
        width: 25%;
        @media only screen and (max-width: 1024px) {
          width: 25%;
          padding: 0;
        }
        .card-thirteen-price {
          color: #4a25aa;
          @media only screen and (max-width: 1024px) {
            display: none !important;
          }
          .price {
            font-weight: bold;
            font-size: 26px;
            margin: 0;
          }
          .discount {
            font-size: 18px;
            font-weight: bold;
            text-decoration: line-through;
            color: #616161;
            margin: 0;
          }
        }
        .btn-ikuti-kelas {
          background: #4a25aa;
          font-size: 16px;
          padding: 5px 18px;
          color: white;
          border-radius: 5px;
          text-decoration: none;
          @media only screen and (max-width: 1024px) {
            padding: 5px 8px;
            border-radius: 5px;
            font-size: 10px;
          }
          &:hover {
            color: white !important;
          }
          &.disable {
            pointer-events: none;
            background: #e0e0e0;
            color: #9e9e9e;
          }
        }
      }
      svg {
        margin-right: 5px;
        @media only screen and (max-width: 1024px) {
          margin-right: 5px;
        }
      }
    }
    .copy-wrapper-bottom {
      @media only screen and (max-width: 1024px) {
        display: flex !important;
        flex-direction: row;
      }
      .card-thirteen-thumbnail-wrapper {
        .card-thirteen-ticket {
          @media only screen and (max-width: 1024px) {
            padding: 2px 6px;
            bottom: 8px;
            left: 8px;
            border-radius: 3px;
          }
          .ticket-price {
            @media only screen and (max-width: 1024px) {
              font-size: 10px;
              margin: 0;
            }
          }
          svg {
            @media only screen and (max-width: 1024px) {
              margin-right: 3px;
            }
          }
        }
      }
      .copy-wrapper {
        @media only screen and (max-width: 1024px) {
          width: 60%;
          padding-left: 15px;
          justify-content: center;
        }
        .card-thirteen-datetime,
        .card-thirteen-location {
          @media only screen and (max-width: 1024px) {
            margin-bottom: 5px;
            color: #616161;
          }
          svg {
            @media only screen and (max-width: 1024px) {
              width: 15px;
              height: 15px;
              margin-right: 5px;
            }
          }
          .location,
          .date {
            @media only screen and (max-width: 1024px) {
              font-size: 12px;
              margin: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              display: -webkit-box;
              -webkit-box-orient: vertical;
            }
          }
        }
        .card-thirteen-price {
          @media only screen and (max-width: 1024px) {
            align-items: flex-end;
            line-height: 20px;
            color: #4a25aa;
          }
          .price {
            @media only screen and (max-width: 1024px) {
              font-weight: bold;
              font-size: 18px;
              margin-right: 10px;
              margin-bottom: 0;
            }
          }
          .discount {
            @media only screen and (max-width: 1024px) {
              font-size: 14px;
              font-weight: bold;
              text-decoration: line-through;
              color: #616161;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
</style>
