var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Link',{attrs:{"to":_vm.to}},[_c('div',{staticClass:"card-eighteen"},[_c('div',{staticClass:"img-wrapper"},[(_vm.image)?_c('ImageResponsive',{staticClass:"card-eighteen-img-top",attrs:{"imageUrl":_vm.image,"imageUrlMobile":_vm.image,"fromUrl":_vm.imageFromUrl,"alt":""}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"card-eighteen-body d-flex flex-column justify-content-between"},[_c('div',{staticClass:"category-wrapper d-flex align-items-center"},_vm._l((_vm.categories.length > 0
            ? _vm.categories.length > 2
              ? _vm.categories.slice(0, 2)
              : _vm.categories
            : [
                {
                  id: '1',
                  title: 'Uncategorized',
                },
              ]),function(category){return _c('div',{key:category.id,staticClass:"card-eighteen-category"},[_vm._v("\n          "+_vm._s(category.title)+" "),_c('span',[_vm._v("•")])])}),0),_vm._v(" "),_c('div',{staticClass:"text-white title-wrapper"},[_c('div',{staticClass:"card-eighteen-title fs-16"},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"card-eighteen-desc fs-12"},[_vm._v("\n          "+_vm._s(_vm.description)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"schedule-wrapper d-flex justify-content-between"},[_c('div',{staticClass:"box card-eighteen-datetime d-flex align-items-center"},[_c('Icons',{attrs:{"name":"calendar","color":"#616161"}}),_vm._v(" "),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm.$moment(_vm.datetime).format("DD/MM/YYYY")))])],1),_vm._v(" "),_c('div',{staticClass:"box card-eighteen-location d-flex align-items-center"},[_c('Icons',{attrs:{"name":"location","color":"#616161"}}),_vm._v(" "),_c('div',{staticClass:"location"},[_vm._v("\n            "+_vm._s(_vm.location)+"\n          ")])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }