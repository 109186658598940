var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-five"},[_c('Link',{staticClass:"card-five-thumbnail",class:{ square: _vm.isThumbnailSquare },attrs:{"to":_vm.to}},[(_vm.image)?_c('ImageResponsive',{staticClass:"card-five-thumbnail-image",attrs:{"imageUrl":_vm.image,"imageUrlMobile":_vm.image,"fromUrl":_vm.imageFromUrl}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-five-thumbnail-duration",class:{ backgroundAds: _vm.isAdvertorial }},[(_vm.isAdvertorial)?_c('p',{staticClass:"advertorial"},[_vm._v("Ad")]):(_vm.isText)?_c('div',{staticClass:"article-reguler"},[_c('Icons',{attrs:{"name":"book"}}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("TEKS")])],1):_c('p',{staticClass:"duration"},[_vm._v(_vm._s(_vm.duration))])])],1),_vm._v(" "),_c('div',{staticClass:"card-five-body",class:{ centerAlign: _vm.withProgram }},[_c('p',{staticClass:"card-five-body-category"},[_c('Link',{directives:[{name:"show",rawName:"v-show",value:(_vm.categorySlug),expression:"categorySlug"}],attrs:{"to":{
          name: 'interest',
          query: {
            type: _vm.categorySlug,
          },
        }}},[_vm._v(_vm._s(_vm.category))])],1),_vm._v(" "),_c('nuxt-link',{staticClass:"card-five-body-title",attrs:{"to":_vm.to}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),(_vm.withProgram)?_c('p',{staticClass:"card-five-body-channel"},[_c('Link',{attrs:{"to":{
          name: 'program-childBrand-slugProgram',
          params: {
            childBrand: _vm.programSlug,
            slugProgram: _vm.channelSlug,
          },
          query: {
            type: 'highlights',
          },
        }}},[_vm._v(_vm._s(_vm.channel))])],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }