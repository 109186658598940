var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-eleven"},[_c('Link',{staticClass:"img-wrapper",attrs:{"to":_vm.to}},[(_vm.image)?_c('ImageResponsive',{staticClass:"card-eleven-img-top",attrs:{"imageUrl":_vm.image,"imageUrlMobile":_vm.image,"fromUrl":_vm.imageFromUrl,"widthMobile":"500","heightMobile":"281","alt":""}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"card-eleven-body"},[_c('div',{staticClass:"card-eleven-body-header"},_vm._l((_vm.categories.length > 0
          ? _vm.categories.length > 2
            ? _vm.categories.slice(0, 2)
            : _vm.categories
          : [
              {
                id: '1',
                title: 'Uncategorized',
              },
            ]),function(category){return _c('div',{key:category.id,staticClass:"card-eleven-category"},[_vm._v("\n        "+_vm._s(category.title)+" "),_c('span',[_vm._v("•")])])}),0),_vm._v(" "),_c('Link',{staticClass:"card-eleven-title",class:{ end: _vm.eventOver },attrs:{"to":_vm.to}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"card-eleven-text"},[_vm._v("\n      "+_vm._s(_vm.description
          ? _vm.description
          : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s")+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"card-eleven-datetime"},[_c('Icons',{attrs:{"name":"calendar","color":"#616161"}}),_vm._v(" "),_c('div',{staticClass:"datetime"},[_vm._v("\n        "+_vm._s(_vm.$moment(_vm.datetime).utc().format("DD/MM/YYYY"))+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"card-eleven-location"},[_c('Icons',{attrs:{"name":"location","color":"#616161"}}),_vm._v(" "),_c('div',{staticClass:"location"},[_vm._v("\n        "+_vm._s(_vm.location)+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"card-eleven-body-footer"},[_c('div',{staticClass:"card-eleven-price"},[_c('div',{staticClass:"price",class:{ end: _vm.eventOver }},[_vm._v(_vm._s(_vm.getPrice))]),_vm._v(" "),(_vm.price)?_c('div',{staticClass:"discount"},[_vm._v(_vm._s(_vm.changeToRupiah(_vm.price)))]):_vm._e()]),_vm._v(" "),_c('Link',{staticClass:"btn-daftar-event",class:{ disable: _vm.checkDate },attrs:{"to":_vm.to}},[_vm._v(_vm._s(_vm.eventOver ? "SELESAI" : "DAFTAR"))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }