<template>
  <div class="btn-container" :style="`background-color: ${color}`">
    <Icons :name="icon" />
    <div class="fs-14 fw-500 text-capitalize user-login">{{ label }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: { default: "SIGN IN DENGAN EMAIL" },
    icon: { default: "email" },
    color: { default: "#4A25AA" },
    path: { default: "/" },
  },
};
</script>

<style lang="scss" scoped>
.btn-container {
  cursor: pointer;
  margin-bottom: 20px;
  position: relative;
  color: white;
  height: 50px;
  // width: 482px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  .icon {
    padding-right: 8px;
  }
}
</style>
