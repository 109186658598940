<template>
  <Link
    :to="to"
    class="card-seven-wrapper"
    :class="$route.params.slug === slug ? 'active' : ''"
  >
    <div class="card-seven">
      <ImageResponsive
        v-if="image"
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
      />
      <div class="card-seven-body">
        <div class="card-seven-title">
          {{ title }}
        </div>
        <div class="card-seven-duration">
          <Icons
            name="circle-play"
            :color="$route.params.slug === slug ? '#fff' : ''"
          />
          <div class="duration">09:00 Menit</div>
        </div>
      </div>
    </div>
  </Link>
</template>

<script>
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    Icons,
    ImageResponsive
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    slug: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    to: {
      type: [Object, String],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.card-seven-wrapper {
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid #dddddd;
  &:last-child {
    border: none;
  }
  &:hover {
    background: #4a25aa;
    color: white !important;
    .card-seven {
      .card-seven-body {
        .card-seven-title {
          color: white !important;
          &:hover {
            color: white !important;
          }
        }
        .card-seven-duration {
          ::v-deep svg {
            path {
              fill: white;
            }
          }
        }
      }
    }
  }
  .card-seven {
    display: flex;
    width: 420px;
    height: 100px;
    padding: 0 20px;
    @media only screen and (max-width: 1024px) {
      padding: 0 30px;
    }
    .image {
      width: 45%;
      height: auto;
      border-radius: 8px;
      @media only screen and (max-width: 1024px) {
        border-radius: 6px;
      }
    }
    .card-seven-body {
      width: 55%;
      padding: 0 17px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .card-seven-title {
        font-size: 14px;
        font-weight: 500;
        color: black;
        margin-bottom: 13px;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        &:hover {
          color: black !important;
        }
      }
      .card-seven-duration {
        display: flex;
        align-items: center;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
        .duration {
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  &.active {
    background: #4a25aa;
    color: white !important;
    .card-seven {
      .card-seven-body {
        .card-seven-title {
          color: white !important;
          &:hover {
            color: white !important;
          }
        }
      }
    }
  }
}
</style>
