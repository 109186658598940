<template>
  <div class="card-23">
    <Link :to="to" class="img-wrapper" :aria-label="title" :class="{ disable: isSoon }">
      <ImageResponsive
        v-if="image"
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
        class="card-23-img-top"
        alt=""
      />
    </Link>
    <div class="card-23-body">
      <div class="card-23-body-header">
        <div v-if="checkDataCategory === 'object'" class="card-23-category">
          {{ categories.title }} <span>&bull;</span>
        </div>
        <template v-else>
          <div class="card-23-category" v-for="category in categories.length > 0
            ? categories.length > 2
              ? categories.slice(0, 2)
              : categories
            : [
                {
                  id: '1',
                  title: 'Uncategorized',
                },
              ]"
          :key="category.id"
        >
          {{ category.title }} <span>&bull;</span>
        </div>
        </template>
      </div>
      <Link :to="to" class="card-23-title" :class="{ disable: isSoon }">
        {{ title }}
      </Link>
      <div
        class="card-23-text"
        v-html="
          description
            ? description
            : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'
        "
      ></div>
      <div class="card-23-classType">
        <Icons name="tipe-kelas" color="#616161" />
        <div class="classType">
          {{ classType }}
        </div>
      </div>
      <div class="card-23-body-footer">
        <div class="card-23-price" v-if="!isSoon">
          <div class="price">
            {{ discountPrice == 0 ? "GRATIS" : changeToRupiah(discountPrice) }}
          </div>
          <div class="discount" v-if="discount !== 0">
            {{ changeToRupiah(price) }}
          </div>
        </div>
        <div class="card-23-soon" v-else>SEGERA HADIR</div>
        <Link
          :to="to"
          class="btn-daftar-event"
          :class="{ disable: !checkDate, disable: isSoon }"
          >DAFTAR</Link
        >
      </div>
    </div>
  </div>
</template>

<script>
import ImageResponsive from "@/components/ImageResponsive"
import Link from "@/components/Link"
import Icons from "@/components/Icons"

export default {
  components: {
    ImageResponsive,
    Link,
    Icons
  },
  props: {
    type: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    categories: {
      type: [Object, String, Array],
      default: "",
    },
    classType: {
      type: [Object, String],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    datetime: {
      type: String,
      default: "",
    },
    price: {
      type: Number,
      default: null,
    },
    discountPrice: {
      type: Number,
      default: null,
    },
    discount: {
      type: Number,
      default: null,
    },
    to: {
      type: [Object, String],
      default: "",
    },
    isSoon: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkDate() {
      return true;
      //   if (this.startRegis && this.endRegis) {
      //     const compareDate = this.$moment();
      //     const startDate = this.$moment(this.startRegis);
      //     const endDate = this.$moment(this.endRegis);
      //     const isBetween = compareDate.isBetween(startDate, endDate);
      //     return isBetween;
      //   } else {
      //     return false;
      //   }
    },
    checkDataCategory() {
      return typeof this.categories;
    },
  },
  methods: {
    changeToRupiah(data) {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
      })
        .format(data)
        .split(",")[0]
        .replace(/\s/g, "");
      // let bilangan = data;
      // let reverse = bilangan.toString().split("").reverse().join(""),
      //   ribuan = reverse.match(/\d{1,3}/g);
      // ribuan = ribuan.join(".").split("").reverse().join("");
      // return "Rp. " + ribuan;
    },
  },
};
</script>

<style lang="scss" scoped>
.card-23 {
  border-radius: 6px;
  overflow: hidden;
  width: 350px;
  height: fit-content;
  border: 1px solid rgba(0, 0, 0, 0.125);
  background: white;
  margin-right: 16px;
  @media only screen and (max-width: 1024px) {
    width: 320px;
    height: fit-content;
    margin-right: 8px;
  }
  .disable {
    pointer-events: none;
  }
  .img-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
    // .image {
    //   object-fit: cover;
    //   width: 100%;
    //   height: 100%;
    //   img {
    //     width: 100%;
    //     height: 100% !important;
    //     object-fit: cover;
    //   }
    // }
    .ticket {
      font-size: 12px;
      display: flex;
      align-items: center;
      padding: 4px 8px;
      position: absolute;
      bottom: 10px;
      left: 10px;
      color: white;
      background: rgb(182, 15, 127);
      background: linear-gradient(
        90deg,
        rgba(182, 15, 127, 1) 0%,
        rgba(74, 37, 170, 1) 100%
      );
      border-radius: 5px;
      svg {
        margin-right: 5px;
      }
    }
  }
  .card-23-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
    min-height: 240px;
    .card-23-body-header {
      display: flex;
      margin-bottom: 10px;
      font-size: 14px;
      .card-23-category {
        font-size: 12px;
        color: #4a25aa;
        @media only screen and (max-width: 1024px) {
          white-space: nowrap;
        }
        &:last-child {
          span {
            display: none;
          }
        }
        span {
          padding: 0 8px;
          font-size: 12px;
          @media only screen and (max-width: 1024px) {
            padding: 0 4px;
          }
        }
      }
    }
    .card-23-title {
      font-size: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      color: #0c0b0d;
    }
    .card-23-text {
      font-size: 12px;
      margin-bottom: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      color: #616161;
    }
    .card-23-datetime {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      svg {
        width: 16px;
        height: 16px;
      }
      .datetime {
        font-size: 12px;
        color: #757575;
      }
    }
    .card-23-classType {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      svg {
        width: 16px;
        height: 16px;
      }
      .classType {
        font-size: 12px;
        color: #757575;
      }
    }
    .card-23-body-footer {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .card-23-price {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .price {
          font-size: 16px;
          font-weight: bold;
          margin-right: 20px;
          color: #4a25aa;
        }
        .discount {
          font-size: 12px;
          font-weight: bold;
          text-decoration: line-through;
          color: #616161;
        }
      }
      .card-23-soon {
        font-size: 16px;
        font-weight: bold;
        margin-right: 20px;
        color: #4a25aa;
      }
      .btn-daftar-event {
        font-size: 12px;
        color: white;
        text-decoration: none;
        background: #4a25aa;
        padding: 5px 15px;
        border-radius: 5px;
        &:hover {
          color: white !important;
        }
        &.disable {
          pointer-events: none;
          background: #e0e0e0;
          color: #9e9e9e;
        }
      }
    }
    svg {
      margin-right: 5px;
    }
  }
}
</style>
