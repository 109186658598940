var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Link',{staticClass:"card-video-wrapper swiper-slide",attrs:{"to":_vm.to}},[_c('div',{staticClass:"card-fivteen",class:_vm.$route.params.slug === _vm.slug ? 'card-fivteen--bgBlue' : ''},[_c('div',{staticClass:"card-fivteen__thumbnail"},[(_vm.image)?_c('ImageResponsive',{staticClass:"card-fivteen__thumbnail__image",attrs:{"imageUrl":_vm.image,"imageUrlMobile":_vm.image,"fromUrl":_vm.imageFromUrl}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"card-fivteen__thumbnail__duration"},[_c('Icons',{attrs:{"name":"circle-play","color":"#4A25AA"}}),_vm._v(" "),_c('p',{staticClass:"duration"},[_vm._v("\n          "+_vm._s(_vm.duration)+"\n        ")])],1)],1),_vm._v(" "),_c('div',{staticClass:"card-fivteen__body"},[_c('Link',{staticClass:"card-fivteen__body__category",class:_vm.$route.params.slug === _vm.slug ? 'card-fivteen--white' : '',attrs:{"to":{
          name: 'interest',
          query: {
            type: _vm.categorySlug,
          },
        }}},[_vm._v("\n        "+_vm._s(_vm.category)+"\n      ")]),_vm._v(" "),_c('div',{staticClass:"card-fivteen__body__title",class:_vm.$route.params.slug === _vm.slug ? 'card-fivteen--white' : ''},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")]),_vm._v(" "),_c('Link',{staticClass:"card-fivteen__body__channel",class:_vm.$route.params.slug === _vm.slug ? 'card-fivteen--white' : '',attrs:{"to":{
          name: 'program-childBrand-slugProgram',
          params: {
            childBrand: _vm.programSlug,
            slugProgram: _vm.channelSlug,
          },
          query: {
            type: 'highlights',
          },
        }}},[_vm._v("\n        "+_vm._s(_vm.channel)+"\n      ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }