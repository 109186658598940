<template>
  <div class="card-three">
    <Link class="card-three__thumbnail" :to="toDetail">
      <ImageResponsive
        v-if="image"
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
        class="card-three__thumbnail__image"
      />
      <div class="card-three__thumbnail__total-video">
        <Icons name="circle-play" color="#ffffff" />
        <p class="total-video">{{ totalVideo }} Video</p>
      </div>
    </Link>
    <div class="card-three__body">
      <h2 class="card-three__body__title">{{ title }}</h2>
      <Link class="card-three__body__playlist" :to="to"
        >Lihat Playlist Selengkapnya</Link
      >
    </div>
  </div>
</template>

<script>
import Icons from "@/components/Icons"
import Link from "@/components/Link"
import ImageResponsive from "@/components/ImageResponsive"

export default {
  components: {
    ImageResponsive,
    Icons,
    Link
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    totalVideo: {
      type: [String, Number],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    to: {
      type: [Object, String],
      default: "",
    },
    toDetail: {
      type: [Object, String],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.card-three {
  width: 250px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  &__thumbnail {
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    @media only screen and (max-width: 1024px) {
      border-radius: 6px;
    }
    &__image {
      width: 100%;
      height: auto;
    }
    &__total-video {
      width: 100%;
      height: auto;
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 0;
      svg {
        width: 14px;
        height: 14px;
        margin-right: 3px;
        @media only screen and (max-width: 1024px) {
          width: 10px;
          height: 10px;
          margin-right: 3px;
        }
      }
      .total-video {
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
    }
  }
  &__body {
    padding: 16px 0;
    &__title {
      font-size: 16px;
      font-weight: 500;
      color: #0c0b0d;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      @media only screen and (max-width: 1024px) {
        font-size: 16px;
      }
    }
    &__playlist {
      font-size: 12px;
      font-weight: 500;
      margin: 0;
      color: #4a25aa;
      text-decoration: underline;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
      }
      &:hover {
        color: #4a25aa !important;
      }
      &:after {
        content: "\00BB";
        font-size: 16px;
        margin-left: 6px;
        display: inline-block;
        line-height: 0;
      }
    }
  }
}
</style>
