var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-23"},[_c('Link',{staticClass:"img-wrapper",class:{ disable: _vm.isSoon },attrs:{"to":_vm.to,"aria-label":_vm.title}},[(_vm.image)?_c('ImageResponsive',{staticClass:"card-23-img-top",attrs:{"imageUrl":_vm.image,"imageUrlMobile":_vm.image,"fromUrl":_vm.imageFromUrl,"alt":""}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"card-23-body"},[_c('div',{staticClass:"card-23-body-header"},[(_vm.checkDataCategory === 'object')?_c('div',{staticClass:"card-23-category"},[_vm._v("\n        "+_vm._s(_vm.categories.title)+" "),_c('span',[_vm._v("•")])]):_vm._l((_vm.categories.length > 0
          ? _vm.categories.length > 2
            ? _vm.categories.slice(0, 2)
            : _vm.categories
          : [
              {
                id: '1',
                title: 'Uncategorized',
              },
            ]),function(category){return _c('div',{key:category.id,staticClass:"card-23-category"},[_vm._v("\n        "+_vm._s(category.title)+" "),_c('span',[_vm._v("•")])])})],2),_vm._v(" "),_c('Link',{staticClass:"card-23-title",class:{ disable: _vm.isSoon },attrs:{"to":_vm.to}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"card-23-text",domProps:{"innerHTML":_vm._s(
        _vm.description
          ? _vm.description
          : 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s'
      )}}),_vm._v(" "),_c('div',{staticClass:"card-23-classType"},[_c('Icons',{attrs:{"name":"tipe-kelas","color":"#616161"}}),_vm._v(" "),_c('div',{staticClass:"classType"},[_vm._v("\n        "+_vm._s(_vm.classType)+"\n      ")])],1),_vm._v(" "),_c('div',{staticClass:"card-23-body-footer"},[(!_vm.isSoon)?_c('div',{staticClass:"card-23-price"},[_c('div',{staticClass:"price"},[_vm._v("\n          "+_vm._s(_vm.discountPrice == 0 ? "GRATIS" : _vm.changeToRupiah(_vm.discountPrice))+"\n        ")]),_vm._v(" "),(_vm.discount !== 0)?_c('div',{staticClass:"discount"},[_vm._v("\n          "+_vm._s(_vm.changeToRupiah(_vm.price))+"\n        ")]):_vm._e()]):_c('div',{staticClass:"card-23-soon"},[_vm._v("SEGERA HADIR")]),_vm._v(" "),_c('Link',{staticClass:"btn-daftar-event",class:{ disable: !_vm.checkDate, disable: _vm.isSoon },attrs:{"to":_vm.to}},[_vm._v("DAFTAR")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }