<template>
  <div class="card-eight swiper-slide">
    <div class="card-eight__thumbnail">
      <Link :to="to">
        <ImageResponsive
          v-if="image"
          :imageUrl="image"
          :imageUrlMobile="image"
          :fromUrl="imageFromUrl"
          class="card-eight__thumbnail__image"
        />
      </Link>
      <div
        class="card-eight__thumbnail__duration_live"
        v-if="duration === 'LIVE'"
      >
        <div class="label-live">
          <i class="fa bi-broadcast"></i>
          <span>LIVE</span>
        </div>
      </div>
      <div
        class="card-eight__thumbnail__duration"
        :class="{ backgroundAds: isAdvertorial }"
        v-else
      >
        <p class="advertorial" v-if="isAdvertorial">Ad</p>
        <div class="article-reguler" v-else-if="isText">
          <i class="bi bi-book-half"></i>
          <p class="text">TEKS</p>
        </div>
        <p class="duration" v-else>{{ duration }}</p>
      </div>
    </div>
    <div class="card-eight__body" v-if="duration === 'LIVE'">
      <div class="card-eight__body__category live">
        {{ category }}
      </div>
      <Link :to="to" class="card-eight__body__title">
        {{ title }}
      </Link>
      <div v-if="time" class="card-eight__body__channel live">
        {{
          $moment
            .unix(Math.floor(+time.startTime / 1000.0))
            .local()
            .format("HH:mm")
        }}
        -
        {{
          $moment
            .unix(Math.floor(+time.endTime / 1000.0))
            .local()
            .format("HH:mm")
        }}
        WIB
      </div>
    </div>
    <div class="card-eight__body" v-else>
      <Link
        :to="{
          name: 'interest',
          query: {
            type: categorySlug,
          },
        }"
        class="card-eight__body__category"
      >
        {{ category }}
      </Link>
      <Link :to="to" class="card-eight__body__title">
        {{ title }}
      </Link>
      <Link
        :to="{
          name: 'program-childBrand-slugProgram',
          params: {
            childBrand: programSlug,
            slugProgram: channelSlug,
          },
          query: {
            type: 'highlights',
          },
        }"
        class="card-eight__body__channel"
      >
        {{ channel }}
      </Link>
    </div>
  </div>
</template>

<script>
import Link from "@/components/Link";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    ImageResponsive,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: [Number, String],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    categorySlug: {
      type: String,
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    channelSlug: {
      type: String,
      default: "",
    },
    programSlug: {
      type: String,
      default: "",
    },
    to: {
      type: [Object, String],
      default: "",
    },
    isText: {
      type: Boolean,
      default: false,
    },
    isAdvertorial: {
      type: Boolean,
      default: false,
    },
    time: {
      type: [Object, String],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-eight {
  width: 380px;
  height: fit-content;
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid lightgray;
  @media only screen and (max-width: 1024px) {
    align-items: center;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 0;
    border: none;
  }
  &__thumbnail {
    width: 40%;
    position: relative;
    @media only screen and (max-width: 1024px) {
      width: fit-content;
      height: 80px;
      border-radius: 6px;
    }
    &__image {
      ::v-deep img {
        border-radius: 10px;
        @media only screen and (max-width: 1024px) {
          height: 100%;
          width: auto;
          border-radius: 6px;
        }
      }
    }
    &__duration {
      background: rgba(0, 0, 0, 0.7);
      padding: 3px 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      @media only screen and (max-width: 1024px) {
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .article-reguler {
        display: flex;
        align-items: center;
        .bi-book-half {
          width: 16px;
          height: auto;
          margin-right: 2px;
          color: #ffffff;
        }
        .text {
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
          margin: 0;
        }
      }
      &.backgroundAds {
        background: rgba(128, 128, 128, 0.7);
        padding: 3px 8px;
      }
      .advertorial {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .duration {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .label-live {
        background: #e73638;
      }
    }

    &__duration_live {
      background: #e73638;
      padding: 3px 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      @media only screen and (max-width: 1024px) {
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .label-live {
        display: flex;
        align-items: center;
        i {
          padding: 0 3px;
          color: #eeeeee;
          font-weight: 600;
        }
        span {
          font-size: 10px;
          color: #eeeeee;
          font-weight: 600;
        }
      }
    }
  }
  &__body {
    width: 60%;
    align-self: center;
    padding-left: 20px;
    @media only screen and (max-width: 1024px) {
      padding-left: 12px;
      width: -webkit-fill-available;
    }
    &__category {
      font-size: 12px;
      font-weight: 400;
      color: #4a25aa;
      margin-bottom: 5px;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
        margin-bottom: 4px;
      }
      &.live {
        color: #616161 !important;
      }
    }
    &__title {
      font-size: 14px;
      font-weight: 500;
      color: black;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
    &__channel {
      font-size: 12px;
      font-weight: 400;
      margin: 0;
      color: #4a25aa;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
      }
      &.live {
        color: #616161 !important;
      }
    }
  }
}
</style>
