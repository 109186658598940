<template>
  <div class="card-five">
    <Link
      :to="to"
      class="card-five-thumbnail"
      :class="{ square: isThumbnailSquare }"
    >
      <ImageResponsive
        v-if="image"
        :imageUrl="image"
        :imageUrlMobile="image"
        :fromUrl="imageFromUrl"
        class="card-five-thumbnail-image"
      />
      <div
        class="card-five-thumbnail-duration"
        :class="{ backgroundAds: isAdvertorial }"
      >
        <p class="advertorial" v-if="isAdvertorial">Ad</p>
        <div class="article-reguler" v-else-if="isText">
          <Icons name="book" />
          <p class="text">TEKS</p>
        </div>
        <p class="duration" v-else>{{ duration }}</p>
      </div>
    </Link>
    <div class="card-five-body" :class="{ centerAlign: withProgram }">
      <p class="card-five-body-category">
        <Link
          v-show="categorySlug"
          :to="{
            name: 'interest',
            query: {
              type: categorySlug,
            },
          }"
          >{{ category }}</Link
        >
      </p>
      <nuxt-link :to="to" class="card-five-body-title">
        {{ title }}
      </nuxt-link>
      <p class="card-five-body-channel" v-if="withProgram">
        <Link
          :to="{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: programSlug,
              slugProgram: channelSlug,
            },
            query: {
              type: 'highlights',
            },
          }"
          >{{ channel }}</Link
        >
      </p>
    </div>
  </div>
</template>

<script>
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    Icons,
    ImageResponsive,
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    duration: {
      type: [Number, String],
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    channelSlug: {
      type: String,
      default: "",
    },
    programSlug: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    categorySlug: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [Object, String],
      default: "",
    },
    isText: {
      type: Boolean,
      default: false,
    },
    isAdvertorial: {
      type: Boolean,
      default: false,
    },
    isThumbnailSquare: {
      type: Boolean,
      default: false,
    },
    withProgram: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.card-five {
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding: 16px 0;
  border-bottom: 1px solid lightgray;
  @media only screen and (max-width: 1024px) {
    width: 100%;
    align-items: center;
  }
  &:nth-child(6) {
    padding-top: 0;
  }
  &:last-child {
    border: none;
    padding-bottom: 0;
  }
  .card-five-thumbnail {
    width: 219px;
    position: relative;
    @media only screen and (max-width: 1024px) {
      width: fit-content;
      height: 80px;
    }
    .card-five-thumbnail-image {
      ::v-deep img {
        border-radius: 10px;
        @media only screen and (max-width: 1024px) {
          height: 100%;
          width: auto;
          border-radius: 6px;
        }
      }
    }
    .card-five-thumbnail-duration {
      background: rgba(0, 0, 0, 0.7);
      padding: 3px 6px;
      position: absolute;
      bottom: 0;
      right: 0;
      border-top-left-radius: 10px;
      border-bottom-right-radius: 10px;
      @media only screen and (max-width: 1024px) {
        border-top-left-radius: 6px;
        border-bottom-right-radius: 6px;
      }
      .article-reguler {
        display: flex;
        align-items: center;
        .bi-book-half {
          width: 16px;
          height: auto;
          margin-right: 2px;
          color: #ffffff;
        }
        .text {
          font-size: 10px;
          font-weight: 500;
          color: #ffffff;
          margin: 0;
        }
      }
      &.backgroundAds {
        background: rgba(128, 128, 128, 0.7);
        padding: 3px 8px;
      }
      .advertorial {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
      .duration {
        font-size: 12px;
        font-weight: 500;
        color: #ffffff;
        margin: 0;
        @media only screen and (max-width: 1024px) {
          font-size: 10px;
        }
      }
    }
    &.square {
      @media only screen and (max-width: 1024px) {
        width: 35%;
        height: auto;
      }
      .card-five-thumbnail-image {
        @media only screen and (max-width: 1024px) {
          height: 25vw;
          width: 25vw;
        }
        ::v-deep img {
          @media only screen and (max-width: 1024px) {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .card-five-body {
    width: 50%;
    padding-left: 20px;
    &.centerAlign {
      align-self: center;
    }
    @media only screen and (max-width: 1024px) {
      width: -webkit-fill-available;
      padding-left: 16px;
    }
    .card-five-body-category {
      font-size: 12px;
      color: #4a25aa;
      margin-bottom: 8px;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
        margin-bottom: 4px;
      }
    }
    .card-five-body-title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      color: black;
      word-break: break-word;
      @media only screen and (max-width: 1024px) {
        font-size: 14px;
        margin-bottom: 4px;
      }
    }
    .card-five-body-channel {
      font-size: 12px;
      font-weight: 400;
      color: #4a25aa;
      margin-bottom: 0;
      @media only screen and (max-width: 1024px) {
        font-size: 12px;
      }
    }
  }
}
</style>
