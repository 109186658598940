var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-nine"},[_c('div',{staticClass:"card-nine__thumbnail"},[_c('Link',{attrs:{"to":_vm.to}},[(_vm.image)?_c('ImageResponsive',{staticClass:"card-nine__thumbnail__image",attrs:{"imageUrl":_vm.image,"imageUrlMobile":_vm.image,"fromUrl":_vm.imageFromUrl}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"card-nine__thumbnail__duration",class:{ backgroundAds: _vm.isAdvertorial }},[(_vm.isAdvertorial)?_c('p',{staticClass:"advertorial"},[_vm._v("Ad")]):(_vm.isText)?_c('div',{staticClass:"article-reguler"},[_c('i',{staticClass:"bi bi-book-half"}),_vm._v(" "),_c('p',{staticClass:"text"},[_vm._v("TEKS")])]):_c('p',{staticClass:"duration"},[_vm._v(_vm._s(_vm.duration))])])],1),_vm._v(" "),_c('div',{staticClass:"card-nine__body"},[_c('div',{staticClass:"card-nine__body__category"},[_vm._v("\n      "+_vm._s(_vm.category)+"\n    ")]),_vm._v(" "),_c('Link',{staticClass:"card-nine__body__title",attrs:{"to":_vm.to}},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('Link',{staticClass:"card-nine__body__channel",attrs:{"to":{
        name: 'program-childBrand-slugProgram',
        params: {
          childBrand: _vm.programSlug,
          slugProgram: _vm.channelSlug,
        },
        query: {
          type: 'highlights',
        },
      }}},[_vm._v("\n      "+_vm._s(_vm.channel)+"\n    ")])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }