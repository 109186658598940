<template>
  <Link :to="to" class="card-video-wrapper swiper-slide">
    <div
      class="card-fivteen"
      :class="$route.params.slug === slug ? 'card-fivteen--bgBlue' : ''"
    >
      <div class="card-fivteen__thumbnail">
        <ImageResponsive
          v-if="image"
          :imageUrl="image"
          :imageUrlMobile="image"
          :fromUrl="imageFromUrl"
          class="card-fivteen__thumbnail__image"
        />
        <div class="card-fivteen__thumbnail__duration">
          <Icons name="circle-play" color="#4A25AA" />
          <p class="duration">
            {{ duration }}
          </p>
        </div>
      </div>
      <div class="card-fivteen__body">
        <Link
          :to="{
            name: 'interest',
            query: {
              type: categorySlug,
            },
          }"
          class="card-fivteen__body__category"
          :class="$route.params.slug === slug ? 'card-fivteen--white' : ''"
        >
          {{ category }}
        </Link>
        <div
          class="card-fivteen__body__title"
          :class="$route.params.slug === slug ? 'card-fivteen--white' : ''"
        >
          {{ title }}
        </div>
        <Link
          :to="{
            name: 'program-childBrand-slugProgram',
            params: {
              childBrand: programSlug,
              slugProgram: channelSlug,
            },
            query: {
              type: 'highlights',
            },
          }"
          class="card-fivteen__body__channel"
          :class="$route.params.slug === slug ? 'card-fivteen--white' : ''"
        >
          {{ channel }}
        </Link>
      </div>
    </div>
  </Link>
</template>

<script>
import Link from "@/components/Link";
import Icons from "@/components/Icons";
import ImageResponsive from "@/components/ImageResponsive";

export default {
  components: {
    Link,
    Icons,
    ImageResponsive
  },
  props: {
    image: {
      type: String,
      default: "",
    },
    imageFromUrl: {
      type: Boolean,
      default: false,
    },
    duration: {
      type: [Number, String],
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    slug: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
    categorySlug: {
      type: String,
      default: "",
    },
    channel: {
      type: String,
      default: "",
    },
    channelSlug: {
      type: String,
      default: "",
    },
    programSlug: {
      type: String,
      default: "",
    },
    to: {
      type: [Object, String],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.card-video-wrapper {
  .card-fivteen {
    width: 380px;
    height: fit-content;
    display: flex;
    padding: 16px 20px;
    border-bottom: 1px solid lightgray;
    &--bgBlue {
      background: #4a25aa;
      color: white !important;
    }
    &--white {
      color: white !important;
    }
    &__thumbnail {
      width: 40%;
      position: relative;
      &__image {
        height: 100%;
        width: auto;
        border-radius: 10px;
        overflow: hidden;
        @media only screen and (max-width: 1024px) {
          border-radius: 6px;
        }
      }
      &__duration {
        display: flex;
        align-items: center;
        background: #ffe900;
        padding: 2px 4px;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        right: 10px;
        @media only screen and (max-width: 1024px) {
          padding: 2px 4px;
        }
        svg {
          width: 15px;
          height: 15px;
          margin-right: 3px;
          @media only screen and (max-width: 1024px) {
            width: 12px;
            height: 12px;
            margin-right: 3px;
          }
        }
        .duration {
          font-size: 12px;
          font-weight: 500;
          color: #4a25aa;
          margin: 0;
          @media only screen and (max-width: 1024px) {
            font-size: 10px;
          }
        }
      }
    }
    &__body {
      width: 60%;
      align-self: center;
      padding-left: 20px;
      @media only screen and (max-width: 1024px) {
        padding-left: 12px;
      }
      &__category {
        font-size: 12px;
        font-weight: 400;
        color: #4a25aa;
        margin-bottom: 5px;
        @media only screen and (max-width: 1024px) {
          font-size: 11px;
          margin-bottom: 4px;
        }
      }
      &__title {
        font-size: 14px;
        font-weight: 500;
        color: black;
        margin-bottom: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        @media only screen and (max-width: 1024px) {
          font-size: 14px;
          margin-bottom: 4px;
        }
      }
      &__channel {
        font-size: 12px;
        font-weight: 400;
        margin: 0;
        color: #4a25aa;
        @media only screen and (max-width: 1024px) {
          font-size: 11px;
        }
      }
    }
  }
}
</style>
